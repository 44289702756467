<template>
  <div ref="fullscreenContainer" class="full-screen-container">
    <!-- <SideControl
      v-if="no_logout && clientStatus === CONNECTED_STATUS"
      @logout="LogOut"
      @fullscreen="fullscreen"
      @disconnectKeyboard="disconnectKeyboardEvents"
    /> -->
    <div
      ref="guacamoleContainer"
      class="full-screen-container nocursor"
      v-show="clientStatus === CONNECTED_STATUS"
      @click="setupKeyboardEvents"
    ></div>

    <!-- Hidden <p> element to trick AudioContext -->
    <p ref="connectionButton" style="margin-bottom: 0.1px; opacity: 0" @click="startViewer"></p>

    <!-- Display Average Latency or Connection Messages -->
    <!--div v-if="displayMessage !== null" class="status-message">
      <pre>{{ displayMessage }}</pre>
    </div-->
  </div>
</template>

<script>
import Guacamole from 'guacamole-common-js'
import CryptoJS from 'crypto-js'
import SideControl from './side-controls.vue'
import { Network } from '@capacitor/network'

export default {
  name: 'GuacamoleStage',
  components: {
    SideControl
  },
  emits: ['display-login'],
  data() {
    return {
      client: null,
      hostname: '',
      username: '',
      password: '',
      port: '',
      scale: 1,
      token: '',
      wrapper: null,
      clientStatus: 0, // Initial status
      optimal_height: 0,
      optimal_width: 0,
      keyboard: null,
      no_logout: true,
      networkConnected: true,
      hasStoredCredentials: false,
      pingResult: null, // Holds the average latency
      pingIntervalId: null, // Stores the interval ID for periodic ping
      pingsLost: false, // Tracks if pings are being lost
      reconnecting: false, // Tracks if reconnection is in progress
      displayMessage: null, // Message to display in place of ping result
      connectionMessage: null, // Holds the current connection status message
      nombre_maquina: ''
    }
  },
  props: {
    encryptedToken: String,
    userData: Object
  },
  computed: {
    CONNECTED_STATUS() {
      return Guacamole.Client.State.CONNECTED
    },
    DISCONNECTED_STATUS() {
      return Guacamole.Client.State.DISCONNECTED
    }
  },
  unmounted() {
    localStorage.removeItem('no_logout')
    this.cleanup()
  },
  mounted() {
    const no_logout = localStorage.getItem('no_logout')
    this.no_logout = !no_logout

    let storedData = localStorage.getItem('credentials')
    let data = storedData ? JSON.parse(storedData) : null
    if (data) {
      this.hasStoredCredentials = true
      this.hostname = data.hostname
      this.username = data.username
      this.password = data.password
      this.port = data.port
      this.nombre_maquina = data.nombre_maquina
    } else {
      if (this.userData) {
        data = this.userData
      } else if (this.encryptedToken) {
        data = this.decryptToken(this.encryptedToken)
      }

      if (data) {
        this.hostname = data.hostname
        this.username = data.username
        this.password = data.password
        this.port = data.port
        this.port = data.nombre_maquina
        console.log(data)
        localStorage.setItem('credentials', JSON.stringify(data))
      }
    }

    // Trigger the click on the hidden <p> element
    this.$nextTick(() => {
      if (this.$refs.connectionButton) {
        this.$refs.connectionButton.click()
      }
    })

    window.addEventListener('resize', this.resizeGuacamoleDisplay)

    // Start the periodic ping test
    this.ping() // Run once immediately
    this.pingIntervalId = setInterval(this.ping, 5000) // Ping every 5 seconds
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeGuacamoleDisplay)
    this.cleanup()
  },
  watch: {
    clientStatus(newStatus) {
      if (newStatus === this.CONNECTED_STATUS) {
        if (this.$refs.guacamoleContainer && this.client) {
          // Clear the container before appending to avoid duplicates
          this.$refs.guacamoleContainer.innerHTML = ''
          this.$refs.guacamoleContainer.appendChild(this.client.getDisplay().getElement())
        }
        // Reset the display message to show ping results
        this.connectionMessage = null
        this.updateDisplayMessage()
      } else if (newStatus === this.DISCONNECTED_STATUS) {
        // Handle disconnection
        this.handleClientDisconnected()
      }
    },
    networkConnected(newStatus) {
      if (!newStatus) {
        this.clientStatus = 5 // Custom status for network disconnected
        this.connectionMessage = 'Network Disconnected'
        this.updateDisplayMessage()
      }
    },
    pingResult(newPingResult) {
      if (this.clientStatus === this.CONNECTED_STATUS && !this.connectionMessage) {
        this.displayMessage = `Average Latency: ${newPingResult} ms`
      }
    }
  },
  methods: {
    retryRequests() {
      // Retry any pending requests or reinitialize your connection
      if (this.clientStatus === this.DISCONNECTED_STATUS) {
        this.attemptReconnection()
      }
    },
    cleanup() {
      // Clear the ping interval to prevent memory leaks
      if (this.pingIntervalId) {
        clearInterval(this.pingIntervalId)
        this.pingIntervalId = null
      }
      // Disconnect the client if it's still connected
      if (this.client) {
        this.client.disconnect()
        this.client = null
      }
      // Remove event listeners
      window.removeEventListener('resize', this.resizeGuacamoleDisplay)
    },
    // Method to start the viewer
    startViewer() {
      this.initializeGuacamoleClient()
    },
    attemptReconnection() {
      if (this.reconnecting) return
      this.reconnecting = true
      this.cleanup()
      // Reset clientStatus to trigger the connecting view if you have one
      this.clientStatus = Guacamole.Client.State.CONNECTING
      this.initializeGuacamoleClient()
      this.reconnecting = false
    },
    reconnect() {
      let hostname_code = localStorage.getItem('hostname_code')

      fetch('https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' + encodeURIComponent(hostname_code))

      location.reload()
    },
    async initializeGuacamoleClient() {
      const container = this.$refs.guacamoleContainer
      console.log(container)
      if (container == 0) {
      }

      const width = container.offsetWidth
      const height = container.offsetHeight

      this.optimal_height = height
      this.optimal_width = width
      this.token =
        this.encryptToken({
          connection: {
            type: 'rdp',
            settings: {
              hostname: this.hostname,
              username: this.username,
              password: this.password,
              'enable-drive': true,
              port: '3389',
              'create-drive-path': false,
              'enable-audio-input': true,
              'enable-audio': true,
              security: 'any',
              'ignore-cert': true,
              'enable-wallpaper': true,
              'server-layout': 'es-latam-qwerty',
              'resize-method': 'display-update',
              cursor: false
            }
          }
        }) +
        `&width=${window.innerWidth}` +
        `&height=${window.innerHeight}` +
        `&GUAC_AUDIO=audio/L8&GUAC_AUDIO=audio/L16`

      this.clientStatus = Guacamole.Client.State.IDLE
      const tunnel = new Guacamole.WebSocketTunnel('wss://rafale.qinaya.co/')
      this.client = new Guacamole.Client(tunnel)

      // Handle tunnel state changes
      tunnel.onstatechange = (state) => {
        switch (state) {
          case Guacamole.Tunnel.State.CONNECTING:
            console.log('Tunnel is connecting')
            this.connectionMessage = 'Tunnel Connecting'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.OPEN:
            console.log('Tunnel is open')
            this.connectionMessage = 'Tunnel Open'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.UNSTABLE:
            console.log('Tunnel is unstable')
            this.connectionMessage = 'Tunnel Unstable'
            this.updateDisplayMessage()
            break
          case Guacamole.Tunnel.State.CLOSED:
            console.log('Tunnel is closed')
            this.connectionMessage = 'Connection Lost'
            this.updateDisplayMessage()
            this.handleTunnelClosed()
            break
        }
      }

      tunnel.onerror = (status) => {
        console.error(`Tunnel error: ${JSON.stringify(status)}`)
        this.connectionMessage = 'Connection Lost'
        this.updateDisplayMessage()
        this.handleTunnelError(status)
      }

      this.setupKeyboardEvents()
      this.setupMouseEvents()
      this.setupTouchEvents()

      this.client.onstatechange = (state) => {
        this.clientStatus = state
        console.log('Client State: ' + state)
        switch (state) {
          case Guacamole.Client.State.IDLE:
            // Handle idle state
            break
          case Guacamole.Client.State.CONNECTING:
            // Handle connecting state
            break
          case Guacamole.Client.State.WAITING:
            // Handle waiting state
            break
          case Guacamole.Client.State.CONNECTED:
            console.log('Client connected, initializing audio...')
            if (!this.client) return

            this.fullscreen()

            this.requestAudioStream()
            // Clear any connection messages
            this.connectionMessage = null
            this.updateDisplayMessage()
            break
          case Guacamole.Client.State.DISCONNECTING:
            // Handle disconnecting state
            console.log('Client is disconnecting...')
            break
          case Guacamole.Client.State.DISCONNECTED:
            // Handle disconnected state
            console.log('Client is disconnected.')
            this.connectionMessage = 'Connection Lost'
            this.updateDisplayMessage()
            this.handleClientDisconnected()
            break
        }
      }

      this.client.onerror = (error) => {
        console.error(`Client error: ${JSON.stringify(error)}`)
        if (this.client) {
          this.client.disconnect()
        }
        this.connectionMessage = 'Connection Lost'
        this.updateDisplayMessage()
        this.handleClientError(error)
      }

      this.client.connect('token=' + this.token)

      Network.addListener('networkStatusChange', (status) => {
        console.log('Network status changed', status)
        this.networkConnected = status.connected

        if (status.connected) {
          console.log('Network reconnected, refreshing the page...')
          // Reload the page to reinitialize the client
          let hostname_code = localStorage.getItem('hostname_code')

      fetch('https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' + encodeURIComponent(hostname_code))
          location.reload()
        } else {
          this.connectionMessage = 'Network Disconnected'
          this.updateDisplayMessage()
        }
      })
    },
    updateDisplayMessage() {
      if (this.connectionMessage) {
        this.displayMessage = this.connectionMessage
      } else if (this.pingResult !== null) {
        this.displayMessage = `Average Latency: ${this.pingResult} ms`
      } else {
        this.displayMessage = null
      }
    },
    handleTunnelClosed() {
      console.log('Handling tunnel closed')
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      let hostname_code = localStorage.getItem('hostname_code')

      fetch('https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' + encodeURIComponent(hostname_code))
      location.reload()
    },
    handleTunnelError(status) {
      console.log('Handling tunnel error')
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      let hostname_code = localStorage.getItem('hostname_code')

      fetch('https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' + encodeURIComponent(hostname_code))
      location.reload()
    },
    handleClientDisconnected() {
      console.log('Handling client disconnection')
      // Update clientStatus to show the disconnected message
      this.clientStatus = Guacamole.Client.State.DISCONNECTED
      // Attempt reconnection or inform the user
    },
    handleClientError(error) {
      console.log('Handling client error')
      // Handle client error
      // Attempt reconnection or inform the user
    },
    requestAudioStream() {
      if (!this.client) return
      let stream = this.client.createAudioStream(`audio/L16;rate=44100,channels=2`)
      let recorder = Guacamole.AudioRecorder.getInstance(stream, 'audio/L16;rate=44100,channels=2')
      if (!recorder) {
        stream.sendEnd()
      } else {
        recorder.onclose = this.requestAudioStream.bind(this.client)
      }
    },
    resizeGuacamoleDisplay() {
      const container = this.$refs.guacamoleContainer

      const newWidth = container.offsetWidth
      const newHeight = container.offsetHeight

      if (this.client) {
        const display = this.client.getDisplay()
        this.scale = newWidth / display.getWidth()

        if (this.client) {
          this.scale = newWidth / display.getWidth()
          this.client.sendSize(newWidth, newHeight)
        }
      }
    },
    fullscreen() {
      console.log('asaa')
      const elem = this.$refs.fullscreenContainer

      if (!document.fullscreenElement) {
        console.log('ccc')

        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          /* Firefox */
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          /* Chrome, Safari & Opera */
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          /* IE/Edge */
          elem.msRequestFullscreen()
        }
      } else {
        console.log('dddddd')

        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          /* Firefox */
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          document.webkitExitFullscreen()
        } else if (document.msExitFullscreen) {
          /* IE/Edge */
          document.msExitFullscreen()
        }
      }
    },
    setupKeyboardEvents() {
      if (this.keyboard != null) return
      if (!this.client) return
      console.log('Setting up keyboard events')

      const guac = this.client
      this.keyboard = new Guacamole.Keyboard(document)

      this.keyboard.onkeydown = function (keysym) {
        guac.sendKeyEvent(1, keysym)
      }

      this.keyboard.onkeyup = function (keysym) {
        guac.sendKeyEvent(0, keysym)
      }
    },
    disconnectKeyboardEvents() {
      console.log('Disconnecting keyboard events')
      if (this.keyboard) {
        this.keyboard.onkeydown = null
        this.keyboard.onkeyup = null
        this.keyboard = null
      }
    },
    setupMouseEvents() {
      if (!this.client) return
      const guac = this.client
      const displayElement = guac.getDisplay().getElement()
      const mouse = new Guacamole.Mouse(displayElement)

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          (mouseState) => {
            guac.sendMouseState(mouseState)
          }
    },
    setupTouchEvents() {
      if (!this.client) return
      const guac = this.client
      const mouse = new Guacamole.Mouse.Touchpad(guac.getDisplay().getElement())

      mouse.onmousedown =
        mouse.onmouseup =
        mouse.onmousemove =
          function (mouseState) {
            guac.sendMouseState(mouseState)
          }
    },
    encryptToken(value) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'
      const iv = CryptoJS.lib.WordArray.random(128 / 8)

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const data = {
        iv: CryptoJS.enc.Base64.stringify(iv),
        value: encrypted.toString()
      }

      const json = JSON.stringify(data)
      return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(json))
    },
    decryptToken(encryptedToken) {
      const SECRET_KEY = 'YJdej0QhgdJ7Phagg9k0CFob9p3mbYpY'

      const decodedData = CryptoJS.enc.Base64.parse(encryptedToken).toString(CryptoJS.enc.Utf8)
      const data = JSON.parse(decodedData)

      const iv = CryptoJS.enc.Base64.parse(data.iv)
      const encryptedValue = data.value

      const key = CryptoJS.enc.Utf8.parse(SECRET_KEY)

      const decrypted = CryptoJS.AES.decrypt(encryptedValue, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

      const decryptedValue = CryptoJS.enc.Utf8.stringify(decrypted)
      return JSON.parse(decryptedValue)
    },
    LogOut() {
      localStorage.clear()
      this.cleanup()
      this.clientStatus = 0
      this.disconnectKeyboardEvents()
      this.$emit('display-login', null)
    },
    // onePing function to measure latency for a single request
    async onePing() {
      let pingTime = 0.0

      let options = {
        method: 'GET',
        mode: 'no-cors',
        cache: 'no-cache'
      }

      let start = Date.now()
      try {
        await fetch('https://ping.qinaya.co/', options)
        pingTime = Date.now() - start
      } catch (error) {
        pingTime = -1
      }
      return pingTime
    },
    // Ping function to calculate average latency and update pingResult
    async ping() {
      let packetsSent = 4
      let packetsReceived = 0
      let packetsLost = 0
      let minTime = 10000000000.0
      let maxTime = 0.0
      let totalTime = 0.0
      let avgTime = 0.0
      let prevTime = 0.0
      let jitter = 0.0

      // The first two pings are discarded as they might be unusually long
      await this.onePing()
      await this.onePing()

      for (let i = 0; i < packetsSent; i++) {
        let pingTime = await this.onePing()
        if (pingTime == -1) {
          packetsLost++
        } else {
          packetsReceived++
          totalTime += pingTime
          if (pingTime < minTime) minTime = pingTime
          if (pingTime > maxTime) maxTime = pingTime

          if (i > 0) jitter += Math.abs(pingTime - prevTime)
          prevTime = pingTime
        }
      }

      if (packetsLost > 0 || packetsSent < 2) jitter = null
      else jitter = jitter / (packetsReceived - 1)

      if (packetsReceived > 0) {
        avgTime = (totalTime / packetsReceived).toFixed(2) // Round to 2 decimal places

        let data = new FormData()
        let hostname_code = localStorage.getItem('hostname_code')

        data.append('hostname', hostname_code)
        data.append('packets_sent', packetsSent)
        data.append('packets_lost', packetsLost)
        data.append('average_time', avgTime)
        data.append('min_time', minTime)
        data.append('max_time', maxTime)
        data.append('jitter', jitter)

        let sURL = new URLSearchParams(data).toString()
        console.log(sURL)
        fetch('https://panel.qinaya.co/api/mobile.asp?action=ping&' + sURL)

        // If pings were previously lost, and now we have a successful ping, reload the page
        if (this.pingsLost) {
          console.log('Pings reestablished, reloading page...')
          this.pingsLost = false
          // Reload the page to reinitialize the client
          let hostname_code = localStorage.getItem('hostname_code')

      fetch('https://panel.qinaya.co/api/mobile.asp?Action=disconnection&hostname=' + encodeURIComponent(hostname_code))
          location.reload()
        }
      } else {
        avgTime = 'N/A'

        // If pings are lost, set pingsLost to true
        if (!this.pingsLost) {
          console.log('Pings lost, monitoring for reconnection...')
          this.pingsLost = true
        }
      }

      // Update the pingResult data property with the average latency
      this.pingResult = avgTime
      this.updateDisplayMessage()
      console.log('Average Latency:', this.pingResult, 'ms') // Optional: Log the result to the console
    }
  }
}
</script>

<style scoped>
.full-screen-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.nocursor {
  cursor: none;
}

.nocursor:hover {
  cursor: none;
}

/* Styles for the status message display */
.status-message {
  position: absolute;
  top: 10px; /* Position from the top */
  left: 10px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

/* Additional styles if needed */
</style>
