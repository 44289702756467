import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router'

//import DisableDevtool from 'disable-devtool';
//DisableDevtool();

const app = createApp(App)
app.config.globalProperties.$REST_ENDPOINT = 'https://panel.qinaya.co/api/'

app.use(createPinia())

Sentry.init({
  app,
  dsn: "https://f9169f01e0d2681d3e67283e48a06266@o4507982176780288.ingest.de.sentry.io/4507991470899280",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(router)

app.mount('#app')
