<template>
  <div class="main_container">
    <div>
      <div class="card-form">

            <div class="logo">
                <img alt="Vue logo" class="logo" src="/images/logo.png" />
            </div>            
        <div class="cardContent" >
            <br>
            <div class="title" style="font-weight: 700; font-size: x-large; color: rgb(50, 50, 50);">Bienvenido a Qinaya v.{{ version }}</div>
            <div class="content">
                <input class="textInput" type="text" v-model="email" placeholder="Email" />
            </div>
            <div class="content">
                <input class="textInput" type="password" v-model="password" placeholder="Password" />
            </div>

            <p v-if="errors.length">
                <b>Mensaje:</b>
            <ul style="margin: 0%;">
              <li v-for="(error, index) in errors" :key="`error-${index}`" class="error">{{ error }}</li>
            </ul>
            </p>
            <div class="buttonContent" @click="login" v-if="button">
                  <center>  <button class="button" type="submit"> Enviar </button>    </center>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
  import { version } from '../../package.json';
import { Network } from "@capacitor/network"; // Capacitor's Network plugin

export default {
  name: 'loginComponent',
  props: {
    loginAutorized: Boolean
  },
  created() {
    console.log('Created');
    let hostname_code = localStorage.getItem('hostname_code');
    if (hostname_code == null) {
      const randomCode = this.generateRandomCode()
      localStorage.setItem('hostname_code', randomCode);
      return
    }
    let login_token = localStorage.getItem('login_token');
    let user_id = localStorage.getItem('user_id')
    this.hostname_code = hostname_code
    this.verifyTokenAlive()

  Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.networkConnected = status.connected;

      if (status.connected) {
        console.log("Network reconnected, retrying login...");
            location.reload();
      } else {
        this.displayError("Network disconnected, waiting for reconnection...");
      }
    });

  },
  data() {
    return {
      errors: [],
      email: '',
      password: '',
      REST_ENDPOINT: this.$REST_ENDPOINT,
      loading: false,
      button: true,
      version,
      hostname_code: '',
    }
  },
  methods: {
    generateRandomCode(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'; // All letters and digits
    let result = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
    },
    login() {
      this.button = false
      this.errors = []
      this.loading = true

      // Aca se inicia sesion
      if (!this.email) {
        this.displayError('El email es obligatorio')
        return
      }
      if (!this.password) {
        this.displayError('La contraseña es obligatoria')
        return
      }
      if (this.validateEmail()) {
        this.displayError('El email es invalido')
        return
      }

      const params = new URLSearchParams()
      params.append('email', this.email)
      params.append('password', this.password)
      params.append('hostname', this.hostname_code)
      axios.post(`${this.REST_ENDPOINT}mobile.asp?Action=authenticate`, params).then((result) => {
        if (result.data.status == '403') {
          this.email = ''
          this.password = ''
          // localStorage.setItem("token", result.data.token);
          this.displayError('El email o la contraseña son incorrectos')
          return
        }
        if (result.data.status == '401') {
          this.email = ''
          this.password = ''
          // localStorage.setItem("token", result.data.token);
          this.displayError('Contraseña incorrecta')
          return
        }
        if (result.data.status == '404') {
          this.email = ''
          this.password = ''
          this.displayError('El email o la contraseña son incorrectos')
          return
        }
        if (result.data.status == '200') {
          // setTimeout(() => (this.loading = false), 1000)
          // this.loginAutorized = true
          this.email = ''
          this.password = ''
          const DataUser = {
            hostname: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.ip,
            username: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.nombre_maquina,
            port: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.puerto,
            password: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.password
          }
          this.$emit('login-success', DataUser)
          localStorage.setItem('login_token', result.data.access_token);
          localStorage.setItem('no_logout', result.data.user.no_logout);
          localStorage.setItem('user_id', result.data.user.id);

          return
        }
      })
    },
    verifyTokenAlive() {
      //localStorage.clear();
      let login_token = localStorage.getItem('login_token');
      let user_id = localStorage.getItem('user_id')
      //login_token = '';
      //user_id = '';

      const params = new URLSearchParams();
      params.append("access_token", login_token);

      axios
        .post(
          "https://panel.qinaya.co/api/mobile.asp?Action=still_alive",
          params
        )
        .then((response) => {
          // Handle the response here
          if (response.data.status == 200) {
            console.log(response.data)
            this.getMachines(user_id, login_token);
          }
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Error verifying token: ", error);
        });
    },
    getMachines(user, token) {
      const params = new URLSearchParams();

      params.append("user_id", user);
      params.append("access_token", token);
      axios
        .post(`${this.REST_ENDPOINT}mobile.asp?Action=machines`, params)
        .then((result) => {
          console.log(result.data)
          if (result.data.status == "200") {
            const DataUser = {
            hostname: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.ip,
            username: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.nombre_maquina,
            port: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.puerto,
            password: JSON.parse(JSON.stringify(result.data)).machines[0].user_machine.password,
          }
          console.log('getMachines');
          this.$emit('login-success', DataUser)
            return;
          }
        });
    },
    displayError(errors) {
      setTimeout(() => (this.loading = false), 1000);

      setTimeout(() => this.errors.push(errors), 1000);

      // this.errors.push(errors);
      setTimeout(() => (this.errors = []), 4000);
      setTimeout(() => (this.button = true), 4000);

      return;
    },
    validateEmail() {
      if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        return false;
      } else {
        return true;
      }
    },
  }
}
</script>

<style>
.main_container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
}

.logo {
  margin: 0 auto;
  display: center;
  width: 50em;
  justify-content: center;
}


.cardContent {
  width: auto;
  padding: 20px 0px;
  margin: 0 auto;
}

.card-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50%;
  height: auto;
  color: white;
  padding: 20px;
  z-index: 20;

  border-radius: 20px;
}

@media screen and (max-width: 620px) {
  .card-form {
    width: auto;
  }
}

.title {
  text-align: left;
  font-size: 20px;
  width: 280px;
  color: black;
}

.error {
  color: #000000;
  font-weight: bold;
  font-size: 20px;
}
.buttonContent {
  margin-top: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  background-color: #cd1e50;
  border: none;
  color: white;
  width: 15em; /* Adjust this width as needed */
  height: 50px;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 600ms cubic-bezier(0.33, 0.11, 0.02, 0.99),
    transform 600ms cubic-bezier(0.33, 0.11, 0.02, 0.99);
}

.button:hover {
  background-color: #000000;
  color: white;
  transform: scale(1) translateY(-0.2rem);
}

.button:active {
  background-color: #000000;
  color: white;
  transform: scale(1.1);
}


.textInput {
  width: 15em;
  background: rgb(248, 244, 244);
  border: 0px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  text-align: left;
  font-size: large;
  color: #000000;
  font-weight: 400;
}

.content {
  margin: 15px auto;
}
</style>